<template>
  <img 
    v-if="isOpenInModal" 
    class="pointer"
    :alt="description"
    :title="description"
    :src="srcImage" 
    @click="openInModal" />

  <a 
    v-else 
    :href="promoBadgeUrl"
    :target="target">
    <img
      :alt="description"
      :title="description"
      :src="srcImage" />
  </a>
</template>

<script>
 import useBadgeLink from 'Composables/promotions/useBadgeLink';

  export default {
    props: {
      description: {
        type: String,
        required: false,
        default: '',
      },
      srcImage: {
        type: String,
        required: true,
      },
      promoBadgeUrl: {
        type: String,
        required: true,
      },
      windowType: {
        type: String,
        required: false,
        default: '1',
      },
    },
    emits: ['promoSelected'],
    setup(props, { emit }) {
   
      const { isOpenInModal, openInModal, target } = useBadgeLink(props, emit);
      
      return {
        isOpenInModal,
        openInModal,
        target,
      };
    },
  };
</script>